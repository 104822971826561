const profile = {
  initialize: function (){
    this.initializeEvents();
    this.maxNumberFeaturedPublications();
    this.initializeMutationObserver();
  },
  initializeMutationObserver: function (){
    let targetNode = $('researcher-suggestion')[0];
    if(targetNode) {
      const observer = new MutationObserver(function(mutationsList, observer) {
        $(mutationsList).each(function(i, mutation) {
          if (mutation.type === 'childList') {
            if (targetNode.html().trim() === '') {
              const jsCloseButton = $('.js-close-button');
              if (jsCloseButton) {
                jsCloseButton.trigger('click');
              }
            }
          }
        });
      });

      observer.observe(targetNode, { childList: true, subtree: true });
    }
  },
  initializeEvents: function (){
    const self = this;

    $(document).on('click', '.js-btn-recommendation', function (event){
      event.preventDefault();
      const button = $(this);
      const url = button.attr('data-url');
      const allow = parseInt(button.attr('data-allow'));

      $.ajax({
        method: 'POST',
        url: url,
        beforeSend: function (){
          button.prop('disabled', true);
        },
        success: function (response){
          const newText = '<svg class="icon svg--application--like">\n' +
                                       '<use xlink:href="#svg--application--like"></use>\n' +
                                   '</svg>' +
                        response.newAction.charAt(0).toUpperCase() + response.newAction.slice(1);
          button.attr('data-url', response.url);
          button.html(newText);

          if (!allow){
            button.addClass('js-disable-button button--disabled').removeClass('js-btn-recommendation');
          }

          // show button if not have any recommendation yet
          const allRecommendation = button.parent().siblings('.js-all-rec');
          allRecommendation.removeClass('hide');
          // add user to all recommendation list
          const boxId = allRecommendation.children('a').attr('href');
          $(boxId).find('.js-rec-authors').prepend(response.avatar);
        },
        complete: function (){
          button.prop('disabled', false);

          const elementNumber = $('.publication-view__list').find('span.js-recommendation-number');
          const elementText = $('.publication-view__list').find('span.js-recommendation-text');

          let currentRecommendation = $(elementNumber).text();
          currentRecommendation = parseInt(currentRecommendation) + 1;

          $(elementNumber).text(currentRecommendation);

          if (currentRecommendation == 1){
            $(elementText).text('Recommendation');
          } else {
            $(elementText).text('Recommendations');
          }
        }
      });
    });

    $(document).on('click', '.js-feature', function (){
      const url = $(this).attr('data-url');
      const elem = $(this);
      // $(elem).html('Loading...');
      $.ajax({
        method: 'POST',
        url: url,
        beforeSend: function (){
          elem.prop('disabled', true);
        },
        success: function (response){
          let icon = '';

          if (response.newAction == 'unfeature'){
            icon = '<svg class="icon icon--xs svg-filled--application--star"><use xlink:href="#svg-filled--application--star"></use></svg>';
          } else if (response.newAction == 'feature'){
            icon = '<svg class="icon icon--xs svg--application--star"><use xlink:href="#svg--application--star"></use></svg>';
          }

          $(elem).html(icon);
          elem.attr('title', '');
          elem.attr('data-url', response.url);
          self.maxNumberFeaturedPublications();
          // change tooltip text
          if (response.newAction == 'feature'){
            $('#' + elem.attr('data-yeti-box')).text('Mark as featured publication, it will be shown on your profile page');
          } else {
            $('#' + elem.attr('data-yeti-box')).text(capitalizeFirstLetter(response.newAction));
          }
        },
        complete: function (){
          elem.prop('disabled', false);
        }
      });
    });
    $(document).on('click', '.js-show-abstract', function (){
      const element = $(this).data('toggle');
      $('#' + element).toggleClass('hide');
    });

    $(document).on('click', '.js-invite-friend', function (){
      const url = $(this).data('url');
      const self = $(this);

      if (!self.attr('disabled')){
        $.ajax({
          type: 'POST',
          url: url,
          success: function (response){
            FlashMessage.showFlashMessagesCallback();
            if (response.success){
              $(self).attr('disabled', 'disabled');
            }
          }
        });
      }
    });
  },
  maxNumberFeaturedPublications: function (){
    const buttons = $('.feature');
    let active = 0;

    buttons.each(function (){
      if ($(this).attr('data-action') == 'unfeature'){
        active++;
      }
    });

    if (active >= 5){
      buttons.each(function (){
        if ($(this).attr('data-action') == 'feature'){
          $(this).attr('disabled', true);
        }
      });
    } else {
      buttons.attr('disabled', false);
    }
  },
  fillContent: function (element, whatToBlock, callback){
    const url = element.data('url');
    const self = this;
    whatToBlock = (typeof whatToBlock !== 'undefined') ? whatToBlock : element;
    $.ajax({
      url: url,
      method: 'GET',
      beforeSend: function (){
        blockElement(whatToBlock);
      },
      success: function (response){
        element.html(response.responseView);
        $('.tabs').foundation();
        if (typeof callback !== 'undefined'){
          self[callback]();
        }
      },
      complete: function (){
        unblockElement(whatToBlock);
      }
    });
  },
  closeModalIfEmpty: function (){
    if ($('#ajaxModalContentWraper').text().trim() == ''){
      $('#ajaxModalWithJs').foundation('close');
    }
  },
  projectsShowMessage: function (button, response, args){
    button.closest(args.div).remove();

    if ($('.profile-data').length == 0){
      $('.profile-edit-box').append('<p>No projects are being bookmarked.</p>');
    }
  }
};
